.samita_productLabel
{
  margin-top:5px;
}

/* Black Friday Collection template behind nav tiles */

#shopify-section-template--15804185968734__logo_bar_Yy4Dka
{
  background-color:black;
}

/* Black Friday Collection template behind filters */

#shopify-section-template--15804185968734__collection-main-filter
{
    background-color:black;
    color:white;
}

/* Black Friday PDP Banner */
.hero-template--15779372040286__hub_banner_B6Gxqi.hero__overlay::before, .hero-template--15779366502494__hub_banner_JwX8Gz.hero__overlay::before
{
  opacity:0!important;
}

.list_variantss > li
{
  color:black;
}


/*Rebuy Smart Cart Announcement*/
#splide01
{
    background-color:#FF0000;
    color:white;
    font-weight:bold;
}